import React from 'react'
import PropTypes from 'prop-types'
import '../utils/fontface.css'
import Header from '../components/molecules/Header'
import Footer from '../components/molecules/Footer'

import GlobalStyles from '../components/atoms/GlobalStyles'
import {ThemeProvider} from 'styled-components'
import {theme} from '../utils/styling'

function Index({children, location}) {
    const notLinksPage = location.pathname !== '/links/'
    const mainStyling = (n) => {
        return {
            marginTop: n + 'rem',
            marginBottom: '6rem'
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles/>
            <Header path={location.pathname}/>
            <main style={notLinksPage ? mainStyling(8) : mainStyling(3)}>
                {children}
            </main>
            {notLinksPage && <Footer/>}
        </ThemeProvider>
    )
}

Index.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Index
