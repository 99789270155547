exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-links-js": () => import("./../../../src/pages/links.js" /* webpackChunkName: "component---src-pages-links-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/Article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-article-list-page-js": () => import("./../../../src/templates/ArticleListPage.js" /* webpackChunkName: "component---src-templates-article-list-page-js" */),
  "component---src-templates-generic-page-js": () => import("./../../../src/templates/GenericPage.js" /* webpackChunkName: "component---src-templates-generic-page-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/Project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-project-list-page-js": () => import("./../../../src/templates/ProjectListPage.js" /* webpackChunkName: "component---src-templates-project-list-page-js" */)
}

