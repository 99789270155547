import React from 'react'
import {Link} from 'gatsby'
import styled from 'styled-components'

const Holder = styled.nav`
`

const List = styled.ul`
  display: flex;
  list-style: none;
`

const ListItem = styled.li`
  padding-left: 1rem;

  > a {
    text-decoration: none;
    ${(props) => props.theme.fluidType(0.75)};

    &:hover {
      text-decoration: underline;
    }
  }
`

function Navigation({clickHandler}) {
    return (
        <Holder className="navigation">
            <List>
                <ListItem>
                    <Link to="/what-we-do/" onClick={clickHandler}>What We Do</Link>
                </ListItem>
                <ListItem>
                    <Link to="/our-vision/" onClick={clickHandler}>Our Vision</Link>
                </ListItem>
                <ListItem>
                    <Link to="/projects/" onClick={clickHandler}>Projects</Link>
                </ListItem>
                <ListItem>
                    <Link to="/journal/" onClick={clickHandler}>Journal</Link>
                </ListItem>
            </List>
        </Holder>
    )
}

export default Navigation
