import {Link} from 'gatsby'
import React, {useState} from 'react'
import styled from 'styled-components'
import Navigation from './Navigation'
import Logo from '../../assets/svg/logotype.inline.svg'
import SmallNavigation from './SmallNavigation'
import MenuIcon from '../atoms/MenuIcon'
import HeaderScrollTransition from '../atoms/HeaderScrollTransition'

const Holder = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const LargeNavHolder = styled.div`
  display: none;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    display: block;
  }
`

const ButtonHolder = styled.div`
  text-align: right;
  z-index: 100;
  position: relative;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    display: none;
  }
`

function Header({path}) {
    const [show, setShow] = useState(false)

    const handleClick = () => {
        setShow(false)
    }

    return (
        <HeaderScrollTransition>
            <Holder show={show}>
                <Link to="/" className="logo"><Logo/></Link>
                <LargeNavHolder>
                    {path !== '/links/' && <Navigation clickHandler={handleClick}/>}
                </LargeNavHolder>
                <ButtonHolder>
                    <button className="icon" onClick={() => setShow(!show)} aria-label="Open navigation">
                        <MenuIcon show={show}/>
                    </button>
                </ButtonHolder>
                <SmallNavigation show={show} clickHandler={handleClick}/>
            </Holder>
        </HeaderScrollTransition>
    )
}

export default Header
