import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Menu from '../../assets/svg/menu.inline.svg'
import Cross from '../../assets/svg/cross.inline.svg'

const Holder = styled.div`
  position: relative;
  z-index: 100;
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;

  .menu-icon {
    position: absolute;
    overflow: visible;
    opacity: ${props => props.show ? 0 : 1};
    height: 1.5rem;
    width: 1.5rem;
    pointer-events: ${props => props.show ? 'none' : 'auto'};
    transition: opacity 0.25s 0.25s ease-in-out;

    path {
      stroke: ${props => props.theme.colours.blue};
    }
  }

  .cross-icon {
    position: absolute;
    overflow: visible;
    opacity: ${props => props.show ? 1 : 0};
    height: 1.5rem;
    width: 1.5rem;
    pointer-events: ${props => props.show ? 'auto' : 'none'};
    transition: opacity 0.25s 0.25s ease-in-out;

    path {
      stroke: ${props => props.theme.colours.white};
    }
  }

`

function MenuIcon({show}) {
    return (
        <Holder show={show}>
            <Menu className="menu-icon"/>
            <Cross className="cross-icon"/>
        </Holder>
    )
}

MenuIcon.propTypes = {
    show: PropTypes.bool.isRequired,
}

export default MenuIcon