import React from 'react'
import Container from '../atoms/Container'
import styled from 'styled-components'
import {Link} from 'gatsby'

const Wrapper = styled.div`
  background-color: ${props => props.theme.colours.blue};
  color: ${props => props.theme.colours.white};
  user-select: none;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    color: ${props => props.theme.colours.blue};
    background-color: ${props => props.theme.colours.white};
  }

  > div {
    margin-bottom: 0 !important;
  }
`

const Holder = styled.footer`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  padding: 3rem 0 2rem 0;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    border-top: 2px solid ${props => props.theme.colours.blue};
    padding: 2rem 0;
  }

  > div > h3:first-child {
    margin-bottom: 0.5rem;
  }

  @media ( ${props => props.theme.breakpoints.lg} ) {
    > :last-child {
      width: fit-content;
      height: fit-content;
      margin-left: auto;
    }
  }

  h3 {
    margin: 0.1em 0;
  }

  a {
    text-decoration: none;
    display: inline-block;

    &:hover {
      text-decoration: underline;
      text-decoration-color: ${props => props.theme.colours.black};

      h3 {
        color: ${props => props.theme.colours.black};
      }
    }
  }

`

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding-left: 0;
`

const ListItem = styled.li`
  &::before {
    content: "—";
    color: ${props => props.theme.colours.white};
    display: inline-block;
    width: 18px;
    margin-right: 0.45em;
    height: 0.28em;
    @media ( ${props => props.theme.breakpoints.lg} ) {
      color: ${props => props.theme.colours.blue};
    }
  }
`

function Footer() {
    return (
        <Wrapper>
            <Container>
                <Holder>
                    <div>
                        <h3>Connect with us</h3>
                        <List>
                            <ListItem>
                                <a href="https://www.linkedin.com/company/parallellinesdesign/"
                                   rel="noreferrer noopener"
                                   target="_blank"><h3>LinkedIn</h3></a>
                            </ListItem>
                            <ListItem>
                                <a href="https://www.instagram.com/parallellinesdesign/" rel="noreferrer noopener"
                                   target="_blank"><h3>Instagram</h3></a>
                            </ListItem>
                            <ListItem>
                                <a href="https://twitter.com/paral_l_el_" rel="noreferrer noopener" target="_blank">
                                    <h3>Twitter</h3></a>
                            </ListItem>
                            <ListItem>
                                <a href="https://confirmsubscription.com/h/y/665AAA106577F8C8" rel="noreferrer noopener"
                                   target="_blank"><h3>Newsletter</h3></a>
                            </ListItem>
                        </List>
                    </div>
                    <div>
                        <h3>Send us an email:</h3>
                        <a href="mailto:info@parallellines.design" rel="noreferrer noopener">
                            <h3>info@parallellines.design</h3></a>
                    </div>
                    <Link to="/privacy-policy/"><h3>Privacy</h3></Link>
                </Holder>
            </Container>
        </Wrapper>
    )
}

export default Footer
