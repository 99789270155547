import React, {useEffect, useLayoutEffect} from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Navigation from '../molecules/Navigation'
import {Link} from 'gatsby'

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 2rem;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: ${props => props.theme.colours.blue};
  color: ${props => props.theme.colours.white};
  padding: 3rem 1.9rem;
  z-index: 9;
  opacity: ${props => props.show ? '1' : '0'};
  pointer-events: ${props => props.show ? 'auto' : 'none'};
  transition: opacity 0.15s ease-in-out;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    display: none;
  }

  ul {
    opacity: ${props => props.show ? 1 : 0};
    transition: opacity 0.25s 0.25s ease-in-out;
    flex-direction: column;
    margin: 0;
    padding: 0;

    li {
      padding: 0;
    }

    a {
      color: ${props => props.theme.colours.white};
      ${(props) => props.theme.fluidType(10)};
    }
  }
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  opacity: ${props => props.show ? 1 : 0};
  transition: opacity 0.25s 0.25s ease-in-out;
`

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding-left: 0;
`

const ListItem = styled.li`
  a {
    display: inline-block;
    text-decoration: none;
  }

  h3 {
    margin: 0.1em 0;
  }

  &::before {
    content: "—";
    color: ${props => props.theme.colours.white};
    display: inline-block;
    width: 18px;
    margin-right: 0.45em;
    height: 0.28em;
    @media ( ${props => props.theme.breakpoints.md} ) {
      color: ${props => props.theme.colours.blue};
    }
  }
`

function SmallNavigation({show, clickHandler}) {

    useLayoutEffect(() => {
        if (show) {
            // Get original body overflow
            const originalStyle = window.getComputedStyle(document.body).overflow
            // Prevent scrolling on mount
            document.body.style.overflow = 'hidden'
            // Re-enable scrolling when component unmounts
            return () => (document.body.style.overflow = originalStyle)
        }
    }, [show])

    useEffect(() => {
        // First we get the viewport height, and we multiply it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`)

        // We listen to the resize event
        window.addEventListener('resize', () => {
            // We execute the same script as before
            let vh = window.innerHeight * 0.01
            document.documentElement.style.setProperty('--vh', `${vh}px`)
        })
    }, [])

    return (
        <Holder show={show}>
            <Navigation clickHandler={clickHandler}/>
            <Inner show={show}>
                <h3>Connect with us</h3>
                <List>
                    <ListItem>
                        <a href="https://www.linkedin.com/company/parallellinesdesign/"
                           rel="noreferrer noopener"
                           target="_blank"><h3>LinkedIn</h3></a>
                    </ListItem>
                    <ListItem>
                        <a href="https://www.instagram.com/parallellinesdesign/" rel="noreferrer noopener"
                           target="_blank"><h3>Instagram</h3></a>
                    </ListItem>
                    <ListItem>
                        <a href="https://twitter.com/paral_l_el_" rel="noreferrer noopener" target="_blank">
                            <h3>Twitter</h3></a>
                    </ListItem>
                    <ListItem>
                        <a href="https://confirmsubscription.com/h/y/665AAA106577F8C8" rel="noreferrer noopener"
                           target="_blank"><h3>Newsletter</h3></a>
                    </ListItem>
                </List>
                <div>
                    <h3>Send us an email:</h3>
                    <a href="mailto:info@parallellines.design" rel="noreferrer noopener">
                        <h3>info@parallellines.design</h3></a>
                </div>
                <Link to="/privacy-policy/"><h3>Privacy</h3></Link>
            </Inner>
        </Holder>
    )
}

SmallNavigation.propTypes = {
    show: PropTypes.bool.isRequired,
}

export default SmallNavigation