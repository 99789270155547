import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import throttle from 'lodash.throttle'

const Holder = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  max-width: ${props => props.theme.typography.maxScreen}px;
  background-color: ${props => props.theme.colours.white};
  color: ${props => props.show ? props.theme.colours.blue : props.theme.colours.white};
  margin: 0 auto;
  padding: 0 1rem;
  transform: ${props => props.show ? 'translateY(0%)' : 'translateY(-100%)'};
  transition: all 0.25s ease-in-out;
  user-select: none;

  .logotype-group {
    path, polygon, rect {
      fill: ${props => props.theme.colours.blue};
      transition: fill 300ms ease-in-out;
    }
  }

  .logo {
    margin: 0.8rem 0;

    &:hover {
      path, polygon, rect {
        fill: ${props => props.theme.colours.black};
      }
    }
  }
`

function HeaderScrollTransition({children}) {
    const [show, setShow] = useState(true)
    const [lastScrollTop, setLastScrollTop] = useState(0)

    useEffect(() => {
        const onScroll = () => {
            let st = window.pageYOffset || document.documentElement.scrollTop
            if (st > lastScrollTop) {
                setShow(false)
            } else {
                setShow(true)
            }
            setLastScrollTop(st <= 0 ? 0 : st)
        }

        const throttledCount = throttle(onScroll, 100)
        window.addEventListener('scroll', throttledCount)
        return () => window.removeEventListener('scroll', throttledCount)

    }, [lastScrollTop])

    return (
        <Holder show={show} lastScrollTop={lastScrollTop}>
            {children}
        </Holder>
    )
}

export default HeaderScrollTransition